import store from '../../../store'
import { conditionalFilterMaps } from '../../../utils/filterUtils'

export async function sendEvent (socket, event, payload) {
  // Get user from Vuex store instead of Firebase directly
  const user = store.getters['AuthModule/getUser']
  const team = store.getters['AuthModule/getTeam']

  // Get current route from global state
  const location = store.getters['RouterModule/getCurrentRoute']
  const currentParams = store.getters['RouterModule/getCurrentParams']

  // Get current filters and search from global state
  const currentFilters = store.getters['SearchModule/getCurrentFilters']

  const formattedFilters = Object.keys(currentFilters).reduce((formattedOrFilters, filterKey) => {
    if (!currentFilters[filterKey]?.length) {
      // Add it directly to the object
      formattedOrFilters[filterKey] = null
      return formattedOrFilters
    } if (filterKey === 'textSearch') {
      formattedOrFilters[filterKey] = currentFilters[filterKey]
      return formattedOrFilters
    }
    const { value, transform } = conditionalFilterMaps.or[filterKey]

    currentFilters[filterKey].forEach(filter => {
      if (formattedOrFilters[filterKey]) {
        formattedOrFilters[filterKey].push(transform(filter[value]))
      } else {
        formattedOrFilters[filterKey] = [transform(filter[value])]
      }
    })

    return formattedOrFilters
  }, {})

  const eventPayload = {
    event,
    userId: user ? user.token : null,
    teamId: team ? team.id : null,
    // This may be overwritten by the payload
    brandId: location === 'BrandView' ? currentParams?.id : null,
    location,
    filters: formattedFilters,
    ...payload
  }

  console.log('Sending event', eventPayload)

  // Send event
  socket.emit('event', eventPayload)
}
