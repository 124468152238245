<template>
  <div
    class="flex flex-col px-6 h-full overflow-y-auto scrollbar-hide w-min-72 w-72 pb-48"
  >
    <!-- Starred Folder -->
    <div
      class="flex items-center cursor-pointer group p-1 rounded-md duration-300 hover:bg-white hover:bg-opacity-10 h-8 select-none pl-1.5 transition-height"
      :class="{
        'folder-style': openFolders.includes('starredBoards'),
      }"
      @click.stop.prevent="toggleFolderOpen('starredBoards')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        class="mx-1.5 text-white group-hover:opacity-100 transition duration-300 h-5"
        :class="
          openFolders.includes('starredBoards') ? 'opacity-100' : 'opacity-70'
        "
      >
        <path
          d="M9.42929 1.81641C9.65867 1.33898 10.3413 1.33898 10.5707 1.81641L12.5445 5.92472C12.6369 6.11694 12.8206 6.24964 13.0328 6.27742L17.5741 6.87198C18.1021 6.94111 18.3133 7.58926 17.9266 7.95386L14.6065 11.0841C14.4507 11.231 14.3803 11.4464 14.4195 11.6564L15.2533 16.1287C15.3504 16.6494 14.7979 17.0497 14.3301 16.7975L10.3012 14.6258C10.1133 14.5245 9.88671 14.5245 9.69879 14.6258L5.66994 16.7975C5.20214 17.0497 4.64963 16.6494 4.74671 16.1287L5.58051 11.6564C5.61966 11.4464 5.54926 11.231 5.39349 11.0841L2.07338 7.95386C1.68667 7.58926 1.89786 6.94111 2.4259 6.87198L6.96724 6.27742C7.17942 6.24964 7.36312 6.11694 7.45547 5.92472L9.42929 1.81641Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <BaseText
        type="body"
        size="sm"
        class="text-white select-none group-hover:opacity-100 transition duration-300 w-36 truncate"
        :class="
          openFolders.includes('starredBoards') ? 'opacity-100' : 'opacity-70'
        "
      >
        Starred
      </BaseText>
    </div>
    <BaseText
      v-if="
        openFolders.includes('starredBoards') && getStarredBoards?.length > 0
      "
      type="body"
      size="sm"
      class="text-white text-opacity-50 pt-2 pb-1 h-0 transition-heigth duration-300 ml-8 pl-px"
      :class="{
        'h-auto': openFolders.includes('starredBoards'),
      }"
    >
      Boards
    </BaseText>
    <div
      v-if="
        openFolders.includes('starredBoards') && getStarredBoards?.length > 0
      "
      class="border-l border-white border-opacity-10 pl-2 ml-8"
    >
      <router-link
        v-for="(board, boardIndex) in getStarredBoards"
        :key="boardIndex"
        :to="{
          name: 'BoardsDetailsView',
          params: { id: board.id, board: board },
        }"
        class="group"
      >
        <div
          class="flex items-center mt-0.5 cursor-pointer px-1 rounded-md transition duration-300 h-7 hover:bg-white hover:bg-opacity-10"
          :class="{
            'mt-0 pt-0': boardIndex === 0,
            'bg-white bg-opacity-10':
              $route.name === 'BoardsDetailsView' &&
              $route.params.id === board.id,
          }"
        >
          <img
            src="../../assets/icons/board-icon.svg"
            alt=""
          >
          <BaseText
            type="body"
            size="sm"
            class="text-white align-middle ml-1 truncate select-none"
          >
            <!-- Remove the '#' from the board name -->
            {{ board.name.substring(1) }}
          </BaseText>
          <div
            class="ml-auto transition duration-300"
            @click.stop.prevent="() => {}"
          >
            <BaseSelect
              v-if="getStarredBoards?.length > 0"
              v-click-outside="
                () => {
                  closeDropdown();
                }
              "
              :close-dropdown="leaveDropdown"
              no-shadow
              no-padding
              white-background
              item-value="name"
              :items="[
                {
                  name: 'Share',
                  divider: true,
                  action: () => copyBoardLink(board.id)
                },
                {
                  name: 'Edit',
                  action: () => openManageBoardModal(board)
                },
                {
                  name: 'Move Board',
                  action: () => openMoveBoardModal(board, folder),
                },
                {
                  name: 'Un-Star Board',
                  action: () => toggleStarredBoard(board),
                  divider: true,
                },
                {
                  name: 'Delete',
                  action: () => openConfirmDeleteBoardModal(board),
                },
              ]"
              @select="() => {}"
              @input="() => {}"
            >
              <div
                slot="reference"
                class="flex items-center opacity-0 justify-center object-fit text-white group-hover:opacity-100 w-full select-none"
                :class="$route.params.id === board.id ? 'opacity-100' : ''"
                @click="closeDropdown()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  class="w-5"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill="#ffffff"
                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  />
                </svg>
              </div>
              <template #item="{ item }">
                <div
                  class="flex items-center w-full h-full cursor-pointer"
                  @click.prevent="item.action && item.action()"
                >
                  <svg
                    v-if="item.name === 'Share'"
                    width="20"
                    height="20"
                    class="ml-1 mr-2 w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99999 3.125V12.5M9.99999 3.125L13.75 6.875M9.99999 3.125L6.25 6.875M16.875 10.625V15.2083C16.875 16.1288 16.1288 16.875 15.2083 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083V10.625"
                      stroke="#5E6678"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <img
                    v-if="item.name === 'Edit'"
                    src="../../assets/icons/edit-icon.svg"
                    alt=""
                    class="ml-1 mr-2"
                  >
                  <img
                    v-if="item.name === 'Move Board'"
                    src="../../assets/icons/move-board-icon.svg"
                    alt=""
                    class="ml-1 mr-2"
                  >
                  <img
                    v-if="item.name === 'Delete'"
                    src="../../assets/icons/trash-icon.svg"
                    alt=""
                    class="ml-1 mr-2"
                  >
                  <svg
                    v-if="item.name === 'Star Board'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    class="ml-1 mr-2"
                    fill="none"
                  >
                    <g id="star, favorite, award">
                      <path
                        id="Icon"
                        d="M9.42929 1.81641C9.65867 1.33897 10.3413 1.33897 10.5707 1.8164L12.5445 5.92471C12.6369 6.11693 12.8206 6.24963 13.0328 6.27741L17.5741 6.87197C18.1021 6.9411 18.3133 7.58925 17.9266 7.95385L14.6065 11.0841C14.4507 11.231 14.3803 11.4464 14.4195 11.6564L15.2533 16.1286C15.3504 16.6494 14.7979 17.0497 14.3301 16.7975L10.3012 14.6258C10.1133 14.5245 9.88671 14.5245 9.69879 14.6258L5.66994 16.7975C5.20214 17.0497 4.64963 16.6494 4.74671 16.1286L5.58051 11.6564C5.61966 11.4464 5.54926 11.231 5.39349 11.0841L2.07338 7.95385C1.68667 7.58925 1.89786 6.9411 2.4259 6.87197L6.96724 6.27741C7.17942 6.24963 7.36312 6.11693 7.45547 5.92471L9.42929 1.81641Z"
                        stroke="#5E6678"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                  <div
                    v-if="item.name === 'Un-Star Board'"
                    class="w-5 h-5 overflow-hidden flex flex-col relative mx-1.5"
                  >
                    <div class="bg-white hover:bg-background-normal">
                      <svg
                        class="absolute w-5 h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g id="star, favorite, award">
                          <path
                            id="Icon"
                            d="M9.42929 1.81641C9.65867 1.33897 10.3413 1.33897 10.5707 1.8164L12.5445 5.92471C12.6369 6.11693 12.8206 6.24963 13.0328 6.27741L17.5741 6.87197C18.1021 6.9411 18.3133 7.58925 17.9266 7.95385L14.6065 11.0841C14.4507 11.231 14.3803 11.4464 14.4195 11.6564L15.2533 16.1286C15.3504 16.6494 14.7979 17.0497 14.3301 16.7975L10.3012 14.6258C10.1133 14.5245 9.88671 14.5245 9.69879 14.6258L5.66994 16.7975C5.20214 17.0497 4.64963 16.6494 4.74671 16.1286L5.58051 11.6564C5.61966 11.4464 5.54926 11.231 5.39349 11.0841L2.07338 7.95385C1.68667 7.58925 1.89786 6.9411 2.4259 6.87197L6.96724 6.27741C7.17942 6.24963 7.36312 6.11693 7.45547 5.92471L9.42929 1.81641Z"
                            stroke="#5E6678"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </svg>
                    </div>

                    <div class="bg-white hover:bg-background-normal">
                      <svg
                        class="absolute w-5 h-5 hover:opacity-0 transition-all duration-200 ease-linear"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_7870_63048)">
                          <path
                            d="M9.42929 1.98308C9.65867 1.50564 10.3413 1.50564 10.5707 1.98308L12.5445 6.09138C12.6369 6.2836 12.8206 6.4163 13.0328 6.44408L17.5741 7.03864C18.1021 7.10778 18.3133 7.75592 17.9266 8.12052L14.6065 11.2508C14.4507 11.3976 14.3803 11.6131 14.4195 11.8231L15.2533 16.2953C15.3504 16.8161 14.7979 17.2164 14.3301 16.9642L10.3012 14.7924C10.1133 14.6911 9.88671 14.6911 9.69879 14.7924L5.66994 16.9642C5.20214 17.2164 4.64963 16.8161 4.74671 16.2953L5.58051 11.8231C5.61966 11.6131 5.54926 11.3976 5.39349 11.2508L2.07338 8.12052C1.68667 7.75592 1.89786 7.10778 2.4259 7.03864L6.96724 6.44408C7.17942 6.4163 7.36312 6.2836 7.45547 6.09138L9.42929 1.98308Z"
                            fill="#FFEDD5"
                            stroke="#FBBF24"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7870_63048">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.166687)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-muted"
                  >
                    {{ item.name }}
                  </BaseText>
                </div>
              </template>
            </BaseSelect>
          </div>
        </div>
      </router-link>
    </div>
    <!-- Folders -->
    <div
      v-for="(folder, index) in getSearchedFolders"
      :key="`folder-${index}`"
    >
      <div
        class="flex items-center cursor-pointer group p-1 rounded-md transition-height duration-300 hover:bg-white hover:bg-opacity-10 h-8 select-none mt-3"
        :class="{
          'folder-style': getOpenFolders.includes(folder.id),
        }"
        @click.stop.prevent="toggleFolderOpen(folder.id)"
      >
        <FolderIcon
          class="mx-2 group-hover:opacity-100 transition duration-300 select-none"
          :class="
            getOpenFolders.includes(folder.id) ? 'opacity-100' : 'opacity-70'
          "
        />
        <BaseText
          type="body"
          size="sm"
          class="text-white select-none group-hover:opacity-100 transition duration-300 w-36 truncate"
          :class="
            getOpenFolders.includes(folder.id) ? 'opacity-100' : 'opacity-70'
          "
        >
          {{ folder.name || "Untitled Folder" }}
        </BaseText>
        <div
          v-if="folder.id !== 'default'"
          class="ml-auto"
        >
          <BaseSelect
            v-click-outside="
              () => {
                closeDropdown();
              }
            "
            no-shadow
            no-padding
            white-background
            :close-dropdown="leaveDropdown"
            item-value="name"
            :items="[
              { name: 'Edit', action: () => openManageFolderModal(folder) },
              {
                name: 'Delete',
                action: () => openConfirmDeleteFolderModal(folder),
              },
            ]"
            @select="() => {}"
            @input="() => {}"
            @click.stop.native="() => {}"
          >
            <div
              slot="reference"
              class="flex items-center opacity-0 justify-center object-fit text-white group-hover:opacity-100 transition duration-300 select-none"
              :class="
                openFolders.includes(folder.id) ? 'opacity-100' : 'opacity-0'
              "
              @click="closeDropdown()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                class="w-5 select-none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  fill="#ffffff"
                  d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                />
              </svg>
            </div>
            <template #item="{ item }">
              <div
                class="flex items-center w-full h-full cursor-pointer"
                @click="item.action()"
              >
                <img
                  v-if="item.name === 'Edit'"
                  src="../../assets/icons/edit-icon.svg"
                  alt=""
                  class="ml-1 mr-2"
                >
                <img
                  v-if="item.name === 'Delete'"
                  src="../../assets/icons/trash-icon.svg"
                  alt=""
                  class="ml-1 mr-2"
                >
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ item.name }}
                </BaseText>
              </div>
            </template>
          </BaseSelect>
        </div>
      </div>

      <!-- Default Boards Container -->
      <div class="pl-8">
        <BaseText
          v-if="getOpenFolders.includes(folder.id) && (
            folder.boards.length > 0 || (!folder.boards.length && !folder.briefs.length && !folder.spyders.length)
          )"
          type="body"
          size="sm"
          class="text-white text-opacity-50 pt-2 pb-1 h-0 transition-heigth duration-300"
          :class="{
            'h-auto': getOpenFolders.includes(folder.id),
          }"
        >
          Boards
        </BaseText>
        <!-- Board -->
        <div
          v-if="getOpenFolders.includes(folder.id) && (
            folder.boards.length > 0 || (!folder.boards.length && !folder.briefs.length && !folder.spyders.length)
          )"
          class="border-l border-white border-opacity-10 pl-2"
          style="margin-left: 1px"
        >
          <router-link
            v-for="(board, boardIndex) in folder.boards"
            :key="boardIndex"
            :to="{
              name: 'BoardsDetailsView',
              params: { id: board.id, board: board },
            }"
            class="group"
          >
            <div
              class="flex items-center mt-1 cursor-pointer px-1 rounded-md transition duration-200 h-7 hover:bg-white hover:bg-opacity-10"
              :class="{
                'mt-0 pt-0': boardIndex === 0,
                'bg-white bg-opacity-10':
                  $route.name === 'BoardsDetailsView' &&
                  $route.params.id === board.id,
              }"
            >
              <img
                src="../../assets/icons/board-icon.svg"
                alt=""
              >
              <BaseText
                type="body"
                size="sm"
                class="text-white align-middle ml-1.5 truncate select-none"
              >
                <!-- Remove the '#' from the board name -->
                {{ board.name.substring(1) }}
              </BaseText>
              <div
                class="ml-auto transition duration-300"
                @click.stop.prevent="() => {}"
              >
                <BaseSelect
                  v-click-outside="
                    () => {
                      closeDropdown();
                    }
                  "
                  :close-dropdown="leaveDropdown"
                  no-shadow
                  no-padding
                  white-background
                  item-value="name"
                  :items="[
                    {
                      name: 'Share', divider: true,
                      action: () => copyBoardLink(board.id)
                    },
                    { name: 'Edit', action: () => openManageBoardModal(board) },
                    {
                      name: 'Move Board',
                      action: () => openMoveBoardModal(board, folder),
                    },
                    {
                      name: !getUser?.starredBoards?.includes(board.id)
                        ? 'Star Board'
                        : 'Un-Star Board',
                      action: () => toggleStarredBoard(board),
                      divider: true,
                    },
                    {
                      name: 'Delete',
                      action: () => openConfirmDeleteBoardModal(board),
                    },
                  ]"
                  @select="() => {}"
                  @input="() => {}"
                >
                  <div
                    slot="reference"
                    class="flex items-center opacity-0 justify-center object-fit text-white group-hover:opacity-100 w-full select-none
                    transition-opacity duration-200"
                    :class="$route.params.id === board.id ? 'opacity-100' : ''"
                    @click="closeDropdown()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      class="w-5"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        fill="#ffffff"
                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                      />
                    </svg>
                  </div>
                  <template #item="{ item }">
                    <div
                      class="flex items-center w-full h-full cursor-pointer"
                      @click="item.action && item.action()"
                    >
                      <svg
                        v-if="item.name === 'Share'"
                        width="20"
                        height="20"
                        class="ml-1 mr-2 w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99999 3.125V12.5M9.99999 3.125L13.75 6.875M9.99999 3.125L6.25 6.875M16.875 10.625V15.2083C16.875 16.1288 16.1288 16.875 15.2083 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083V10.625"
                          stroke="#5E6678"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <img
                        v-if="item.name === 'Edit'"
                        src="../../assets/icons/edit-icon.svg"
                        alt=""
                        class="ml-1 mr-2"
                      >
                      <img
                        v-if="item.name === 'Move Board'"
                        src="../../assets/icons/move-board-icon.svg"
                        alt=""
                        class="ml-1 mr-2"
                      >
                      <img
                        v-if="item.name === 'Delete'"
                        src="../../assets/icons/trash-icon.svg"
                        alt=""
                        class="ml-1 mr-2"
                      >
                      <svg
                        v-if="item.name === 'Star Board'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        class="ml-1 mr-2"
                        fill="none"
                      >
                        <g id="star, favorite, award">
                          <path
                            id="Icon"
                            d="M9.42929 1.81641C9.65867 1.33897 10.3413 1.33897 10.5707 1.8164L12.5445 5.92471C12.6369 6.11693 12.8206 6.24963 13.0328 6.27741L17.5741 6.87197C18.1021 6.9411 18.3133 7.58925 17.9266 7.95385L14.6065 11.0841C14.4507 11.231 14.3803 11.4464 14.4195 11.6564L15.2533 16.1286C15.3504 16.6494 14.7979 17.0497 14.3301 16.7975L10.3012 14.6258C10.1133 14.5245 9.88671 14.5245 9.69879 14.6258L5.66994 16.7975C5.20214 17.0497 4.64963 16.6494 4.74671 16.1286L5.58051 11.6564C5.61966 11.4464 5.54926 11.231 5.39349 11.0841L2.07338 7.95385C1.68667 7.58925 1.89786 6.9411 2.4259 6.87197L6.96724 6.27741C7.17942 6.24963 7.36312 6.11693 7.45547 5.92471L9.42929 1.81641Z"
                            stroke="#5E6678"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </svg>
                      <div
                        v-if="item.name === 'Un-Star Board'"
                        class="w-5 h-5 overflow-hidden flex flex-col relative mx-1.5"
                      >
                        <div class="bg-white hover:bg-background-normal">
                          <svg
                            class="absolute w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g id="star, favorite, award">
                              <path
                                id="Icon"
                                d="M9.42929 1.81641C9.65867 1.33897 10.3413 1.33897 10.5707 1.8164L12.5445 5.92471C12.6369 6.11693 12.8206 6.24963 13.0328 6.27741L17.5741 6.87197C18.1021 6.9411 18.3133 7.58925 17.9266 7.95385L14.6065 11.0841C14.4507 11.231 14.3803 11.4464 14.4195 11.6564L15.2533 16.1286C15.3504 16.6494 14.7979 17.0497 14.3301 16.7975L10.3012 14.6258C10.1133 14.5245 9.88671 14.5245 9.69879 14.6258L5.66994 16.7975C5.20214 17.0497 4.64963 16.6494 4.74671 16.1286L5.58051 11.6564C5.61966 11.4464 5.54926 11.231 5.39349 11.0841L2.07338 7.95385C1.68667 7.58925 1.89786 6.9411 2.4259 6.87197L6.96724 6.27741C7.17942 6.24963 7.36312 6.11693 7.45547 5.92471L9.42929 1.81641Z"
                                stroke="#5E6678"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </svg>
                        </div>

                        <div class="bg-white hover:bg-background-normal">
                          <svg
                            class="absolute w-5 h-5 hover:opacity-0 transition-all duration-200 ease-linear"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_7870_63048)">
                              <path
                                d="M9.42929 1.98308C9.65867 1.50564 10.3413 1.50564 10.5707 1.98308L12.5445 6.09138C12.6369 6.2836 12.8206 6.4163 13.0328 6.44408L17.5741 7.03864C18.1021 7.10778 18.3133 7.75592 17.9266 8.12052L14.6065 11.2508C14.4507 11.3976 14.3803 11.6131 14.4195 11.8231L15.2533 16.2953C15.3504 16.8161 14.7979 17.2164 14.3301 16.9642L10.3012 14.7924C10.1133 14.6911 9.88671 14.6911 9.69879 14.7924L5.66994 16.9642C5.20214 17.2164 4.64963 16.8161 4.74671 16.2953L5.58051 11.8231C5.61966 11.6131 5.54926 11.3976 5.39349 11.2508L2.07338 8.12052C1.68667 7.75592 1.89786 7.10778 2.4259 7.03864L6.96724 6.44408C7.17942 6.4163 7.36312 6.2836 7.45547 6.09138L9.42929 1.98308Z"
                                fill="#FFEDD5"
                                stroke="#FBBF24"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_7870_63048">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.166687)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <BaseText
                        type="body"
                        size="sm"
                        class="text-text-muted"
                      >
                        {{ item.name }}
                      </BaseText>
                    </div>
                  </template>
                </BaseSelect>
              </div>
            </div>
          </router-link>
          <button class="group flex items-center gap-1.5 px-1 py-1 mt-1 w-full rounded-md transition duration-200 hover:bg-white hover:bg-opacity-10"
          @click="openCreateBoardModal(folder)">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375" stroke="white" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <BaseText type="body" size="sm" class="text-white transition-opacity duration-200 opacity-50 group-hover:opacity-100">
              Create Board
            </BaseText>
          </button>
        </div>
      </div>

      <!-- Folder Briefs -->
      <div
        class="pl-8"
        :class="
          getIsInspoMode
            ? 'opacity-50 cursor-not-allowed pointer-events-none'
            : ''
        "
      >
        <BaseText
          v-if="getOpenFolders.includes(folder.id) && folder.briefs.length > 0"
          type="body"
          size="sm"
          class="text-white text-opacity-50 pt-2 pb-1"
        >
          Briefs
        </BaseText>
        <!-- Board -->
        <div
          v-if="getOpenFolders.includes(folder.id) && folder.briefs.length > 0"
          class="border-l border-white border-opacity-10 pl-2"
          style="margin-left: 1px"
        >
          <router-link
            v-for="(brief, briefIndex) in folder.briefs"
            :key="briefIndex"
            :to="{ name: 'EditBriefView', params: { id: brief.id } }"
            class="group cursor-pointer"
          >
            <div
              class="flex items-center mt-1 cursor-pointer px-1 rounded-md transition duration-200 h-7 hover:bg-white hover:bg-opacity-10"
              :class="{
                'mt-0 pt-0': briefIndex === 0,
                'bg-white bg-opacity-10':
                  $route.name === 'EditBriefView' &&
                  $route.params.id === brief.id,
              }"
            >
              <img
                src="../../assets/icons/clipboard-icon.svg"
                alt=""
                class="opacity-50"
              >
              <BaseText
                type="body"
                size="sm"
                class="text-white align-middle ml-1.5 truncate select-none"
              >
                {{ brief.name }}
              </BaseText>
              <div
                class="ml-auto group transition duration-300"
                @click.stop.prevent="() => {}"
              >
                <BaseSelect
                  v-click-outside="
                    () => {
                      closeDropdown();
                    }
                  "
                  no-shadow
                  no-padding
                  white-background
                  :close-dropdown="leaveDropdown"
                  item-value="name"
                  :items="[
                    { 
                      name: 'Share',
                      divider: true,
                      action: () => copyBriefLink(brief.id),
                    },
                    {
                      name: 'Move Brief',
                      action: () => { openMoveBriefModal(brief, folder); closeDropdown() },
                    },
                    {
                      name: 'Archive',
                      divider: true,
                      action: () => archiveBrief(brief.id),
                    },
                    {
                      name: 'Delete',
                      action: () => openConfirmDeleteBriefModal(brief),
                    },
                  ]"
                  @select="() => {}"
                  @input="() => {}"
                >
                  <div
                    slot="reference"
                    class="flex items-center justify-center object-fit text-white opacity-0 group-hover:opacity-100 transition duration-200 cursor-pointer"
                    :class="$route.params.id === brief.id ? 'opacity-100' : ''"
                    @click="closeDropdown()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      class="w-5"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        fill="#ffffff"
                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                      />
                    </svg>
                  </div>
                  <template #item="{ item }">
                    <div
                      class="flex items-center w-full h-full cursor-pointer"
                      @click.stop.prevent="item.action && item.action()"
                    >
                      <svg
                        v-if="item.name === 'Share'"
                        width="20"
                        height="20"
                        class="ml-1 mr-2 w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99999 3.125V12.5M9.99999 3.125L13.75 6.875M9.99999 3.125L6.25 6.875M16.875 10.625V15.2083C16.875 16.1288 16.1288 16.875 15.2083 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083V10.625"
                          stroke="#5E6678"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <img
                        v-if="item.name === 'Move Brief'"
                        src="../../assets/icons/move-board-icon.svg"
                        alt=""
                        class="ml-1 mr-2"
                      >
                      <img
                        v-if="item.name === 'Archive'"
                        src="../../assets/icons/archive-icon.svg"
                        alt=""
                        class="ml-1 mr-2"
                      >
                      <img
                        v-if="item.name === 'Delete'"
                        src="../../assets/icons/trash-icon.svg"
                        alt=""
                        class="ml-1 mr-2"
                      >
                      <BaseText
                        type="body"
                        size="sm"
                        class="text-text-muted"
                      >
                        {{ item.name }}
                      </BaseText>
                    </div>
                  </template>
                </BaseSelect>
              </div>
            </div>
          </router-link>
          <button class="group flex items-center gap-1.5 px-1 py-1 mt-1 w-full rounded-md transition duration-200 hover:bg-white hover:bg-opacity-10"
          @click="routeToCreateBrief(folder.id)">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375" stroke="white" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <BaseText type="body" size="sm" class="text-white transition-opacity duration-200 opacity-50 group-hover:opacity-100">
              Create Brief
            </BaseText>
          </button>
          <div />
        </div>
      </div>
      <div class="pl-8">
        <BaseText
          v-if="getOpenFolders.includes(folder.id) && folder.spyders.length > 0"
          type="body"
          size="sm"
          class="text-white text-opacity-50 pt-2 pb-1 h-0 transition-heigth duration-300"
          :class="{
            'h-auto': getOpenFolders.includes(folder.id),
          }"
        >
          Spyder
        </BaseText>
        <div
          v-if="getOpenFolders.includes(folder.id) && folder.spyders.length > 0"
          class="border-l border-white border-opacity-10 pl-2"
          style="margin-left: 1px"
        >
          <div
            :is=" spyder?.id && spyder?.name ? 'router-link' : 'div'"
            v-for="(spyder, brandIndex) in folder.spyders"
            :key="brandIndex"
            :to="{ name: 'SpyderView', params: { id: spyder?.id } }"
            class="group"
          >
            <div
              class="flex items-center mt-1 cursor-pointer px-1 rounded-md transition duration-200 h-7 hover:bg-white hover:bg-opacity-10 "
              :class="{
                'mt-0 pt-0': brandIndex === 0,
                'bg-white bg-opacity-10':
                  $route.name === 'SpyderView' &&
                  $route.params.id === spyder?.id,
              }"
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.81791 8.61812C2.79563 8.39765 2.82435 8.17859 2.88891 7.96661C3.31716 6.56049 5.30578 1 9.9735 1C14.5703 1 16.6079 6.53176 17.0563 7.95567C17.1255 8.17546 17.1562 8.40221 17.1324 8.63141C17.0565 9.36258 16.7158 11.1952 15.1913 13.5321C13.8259 15.6379 11.0716 18.0652 10.226 18.7872C10.0796 18.9122 9.86742 18.9137 9.72002 18.7899C8.87417 18.0793 6.1252 15.6914 4.76041 13.5663C3.22633 11.1885 2.89101 9.34138 2.81791 8.61812Z" stroke="white" stroke-opacity="0.5" stroke-width="1.5"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8235 13.0096C14.0261 11.7291 14.739 10.0671 14.8425 8.31668C14.8524 8.15005 14.7527 7.99771 14.5989 7.93289L12.4137 7.01206L10.132 6.05059C10.0307 6.00791 9.91643 6.00803 9.81524 6.05093L5.37602 7.9329C5.22271 7.99789 5.12345 8.14988 5.1332 8.3161C5.23524 10.0558 5.94314 11.7079 7.13792 12.9808C8.07344 14.0162 9.16808 14.6452 9.97341 14.6453C10.8318 14.6453 11.9024 14.0063 12.8235 13.0096Z" fill="white" fill-opacity="0.5"/>
            </svg>
              <BaseText
                type="body"
                size="sm"
                class="text-white align-middle ml-1.5 truncate select-none flex-1"
              >
                {{ spyder?.name || 'In Progress' }}
              </BaseText>
              <div
                class="ml-auto transition duration-300 "
                @click.stop.prevent="()=>{}"
              >
                <BaseSelect
                  v-click-outside="()=>{closeDropdown()}"
                  :close-dropdown="leaveDropdown"
                  no-shadow
                  no-padding
                  white-background
                  item-value="name"
                  :items="[
                    {
                      name: 'Move Brand',
                      divider: true,
                      action: () => { openMoveSpyderModal(spyder, folder); closeDropdown() },
                    },
                    { name: 'Delete', action: () => {removeSpyderBrand(spyder)} }
                  ]"
                  @select="() => {}"
                  @input="() => {}"
                >
                  <div
                    slot="reference"
                    class="flex items-center opacity-0 justify-center object-fit text-white group-hover:opacity-100 w-full select-none
                    transition-opacity duration-200"
                    :class=" spyder?.id && $route.params.id === spyder?.id ? 'opacity-100' : ''"
                    @click="closeDropdown()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      class="w-5"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        fill="#ffffff"
                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                      />
                    </svg>
                  </div>
                  <template #item="{ item }">
                    <div
                      class="flex items-center w-full h-full cursor-pointer"
                      @click="item.action && item.action()"
                    >
                      <img
                        v-if="item.name === 'Edit'"
                        src="../../assets/icons/edit-icon.svg"
                        alt=""
                        class="ml-1 mr-2"
                      >
                      <img
                        v-if="item.name === 'Move Brand'"
                        src="../../assets/icons/move-board-icon.svg"
                        alt=""
                        class="ml-1 mr-2"
                      >
                      <img
                        v-if="item.name === 'Delete'"
                        src="../../assets/icons/trash-icon.svg"
                        alt=""
                        class="ml-1 mr-2"
                      >
                      <svg
                        v-if="item.name === 'Star Board'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        class="ml-1 mr-2"
                        fill="none"
                      >
                        <g id="star, favorite, award">
                          <path
                            id="Icon"
                            d="M9.42929 1.81641C9.65867 1.33897 10.3413 1.33897 10.5707 1.8164L12.5445 5.92471C12.6369 6.11693 12.8206 6.24963 13.0328 6.27741L17.5741 6.87197C18.1021 6.9411 18.3133 7.58925 17.9266 7.95385L14.6065 11.0841C14.4507 11.231 14.3803 11.4464 14.4195 11.6564L15.2533 16.1286C15.3504 16.6494 14.7979 17.0497 14.3301 16.7975L10.3012 14.6258C10.1133 14.5245 9.88671 14.5245 9.69879 14.6258L5.66994 16.7975C5.20214 17.0497 4.64963 16.6494 4.74671 16.1286L5.58051 11.6564C5.61966 11.4464 5.54926 11.231 5.39349 11.0841L2.07338 7.95385C1.68667 7.58925 1.89786 6.9411 2.4259 6.87197L6.96724 6.27741C7.17942 6.24963 7.36312 6.11693 7.45547 5.92471L9.42929 1.81641Z"
                            stroke="#5E6678"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </svg>
                      <div
                        v-if="item.name === 'Un-Star Board'"
                        class="w-5 h-5 overflow-hidden flex flex-col relative mx-1.5"
                      >
                        <div
                          class="bg-white hover:bg-background-normal"
                        >
                          <svg
                            class="absolute w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g id="star, favorite, award">
                              <path
                                id="Icon"
                                d="M9.42929 1.81641C9.65867 1.33897 10.3413 1.33897 10.5707 1.8164L12.5445 5.92471C12.6369 6.11693 12.8206 6.24963 13.0328 6.27741L17.5741 6.87197C18.1021 6.9411 18.3133 7.58925 17.9266 7.95385L14.6065 11.0841C14.4507 11.231 14.3803 11.4464 14.4195 11.6564L15.2533 16.1286C15.3504 16.6494 14.7979 17.0497 14.3301 16.7975L10.3012 14.6258C10.1133 14.5245 9.88671 14.5245 9.69879 14.6258L5.66994 16.7975C5.20214 17.0497 4.64963 16.6494 4.74671 16.1286L5.58051 11.6564C5.61966 11.4464 5.54926 11.231 5.39349 11.0841L2.07338 7.95385C1.68667 7.58925 1.89786 6.9411 2.4259 6.87197L6.96724 6.27741C7.17942 6.24963 7.36312 6.11693 7.45547 5.92471L9.42929 1.81641Z"
                                stroke="#5E6678"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </svg>
                        </div>

                        <div
                          class="bg-white hover:bg-background-normal"
                        >
                          <svg
                            class="absolute w-5 h-5 hover:opacity-0 transition-all duration-200 ease-linear "
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_7870_63048)">
                              <path
                                d="M9.42929 1.98308C9.65867 1.50564 10.3413 1.50564 10.5707 1.98308L12.5445 6.09138C12.6369 6.2836 12.8206 6.4163 13.0328 6.44408L17.5741 7.03864C18.1021 7.10778 18.3133 7.75592 17.9266 8.12052L14.6065 11.2508C14.4507 11.3976 14.3803 11.6131 14.4195 11.8231L15.2533 16.2953C15.3504 16.8161 14.7979 17.2164 14.3301 16.9642L10.3012 14.7924C10.1133 14.6911 9.88671 14.6911 9.69879 14.7924L5.66994 16.9642C5.20214 17.2164 4.64963 16.8161 4.74671 16.2953L5.58051 11.8231C5.61966 11.6131 5.54926 11.3976 5.39349 11.2508L2.07338 8.12052C1.68667 7.75592 1.89786 7.10778 2.4259 7.03864L6.96724 6.44408C7.17942 6.4163 7.36312 6.2836 7.45547 6.09138L9.42929 1.98308Z"
                                fill="#FFEDD5"
                                stroke="#FBBF24"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_7870_63048">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.166687)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <BaseText
                        type="body"
                        size="sm"
                        class="text-text-muted"
                      >
                        {{ item.name }}
                      </BaseText>
                    </div>
                  </template>
                </BaseSelect>
              </div>
            </div>
          </div>
          <button class="group flex items-center gap-1.5 px-1 py-1 mt-1 w-full rounded-md transition duration-200 hover:bg-white hover:bg-opacity-10"
          @click="routeToAddSpyder(folder.id)">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375" stroke="white" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            <BaseText type="body" size="sm" class="text-white transition-opacity duration-200 opacity-50 group-hover:opacity-100">
              Add Spyder
            </BaseText>
          </button>
        </div>
      </div>
    </div>
    <FolderModal
      v-if="showFolderModal || folderModalOpen"
      @close="showFolderModal = false, $emit('update:folderModalOpen', false)"
    />
    <ManageFolderModal
      v-if="showFolderManageModal"
      :folder="folderToManage"
      @close="showFolderManageModal = false"
    />
    <ChangeFolderModal
      v-if="showMoveBoardModal"
      :item="boardToMove"
      itemType="board"
      @close="showMoveBoardModal = false"
    />
    <ChangeFolderModal 
      v-if="showMoveBriefModal"
      :item="briefToMove"
      itemType="brief"
      @close="showMoveBriefModal = false"
    />
    <ChangeFolderModal 
      v-if="showMoveSpyderModal"
      :item="spyderToMove"
      itemType="brand"
      @close="showMoveSpyderModal = false"
    />
    <ConfirmDeleteModal
      v-if="showConfirmDeleteFolderModal"
      folder
      @confirm="deleteFolder()"
      @close="showConfirmDeleteFolderModal = false"
    />
    <ConfirmDeleteModal
      v-if="showConfirmDeleteBoardModal"
      board
      @confirm="handleDeleteBoard()"
      @close="showConfirmDeleteBoardModal = false"
    />
    <ConfirmDeleteModal
      v-if="showConfirmDeleteBriefModal"
      @confirm="handleDeleteBrief()"
      @close="showConfirmDeleteBriefModal = false"
    />
    <ManageBoardModal
      v-if="showManageBoardModal"
      :board="boardToManage"
      :initial-folder="selectedFolder"
      @close="showManageBoardModal = false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import firebase from '../../api/config/FirebaseConfig'
import ManageFolderModal from './ManageFolderModal'
import ChangeFolderModal from '../globals/ChangeFolderModal.vue'
import FolderIcon from '../globals/Icons/FolderIcon.vue'
import FolderModal from './FolderModal.vue'
import ClickOutside from 'vue-click-outside'
import ConfirmDeleteModal from '../globals/ConfirmDeleteModal.vue'
import FirebaseAPI from '../../api/firebase'
import ManageBoardModal from '../boards/ManageBoardModal.vue'
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'

export default {
  name: 'Folders',
  components: {
    FolderIcon,
    FolderModal,
    ManageFolderModal,
    ChangeFolderModal,
    ConfirmDeleteModal,
    ManageBoardModal
  },
  directives: {
    ClickOutside
  },
  props: {
    isBetaUser: {
      type: Boolean,
      default: () => false
    },
    leaveDropdown: {
      type: Boolean
    },
    folderModalOpen: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      folderToManage: {},
      boardToMove: {},
      briefToMove: {},
      spyderToMove: {},
      showMoveBoardModal: false,
      showMoveBriefModal: false,
      showMoveSpyderModal: false,
      showMoveBriefToMoveModal: false,
      showFolderManageModal: false,
      showFolderModal: false,
      showCreateOptions: false,
      showManageBoardModal: false,
      openFolders: [],
      folders: [],
      showConfirmDeleteFolderModal: false,
      folderName: '',
      loadingDelete: false,
      loading: false,
      folder: {},
      editBrief: {},
      shareBoardId: '',
      boardToManage: {},
      selectedFolder: {},
      loadingDeleteBoard: false,
      boardToDelete: {},
      boardToDeleteName: '',
      showConfirmDeleteBoardModal: false,
      briefToDelete: {},
      briefToDeleteName: '',
      showConfirmDeleteBriefModal: false,
      currentUser: {},
      remixiconUrl,

      // route update triggers
      spyderLinkCounter: 0,
      briefsLinkCounter: 0,
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
    ...mapGetters('BoardsModule', ['getBoards', 'getFolders']),
    ...mapGetters('BriefsModule', ['getBriefs', 'getIsInspoMode']),
    ...mapGetters('AuthModule', ['getUser', 'getTeam', 'getUserName', 'getStarredBoards']),
    ...mapGetters('SpyderModule', ['getSpyderSubscriptions']),
    getSearchedFolders () {
      return this.folders.map(folder => {
        const folderMatch = folder.name.toLowerCase().search(this.search.toLowerCase()) !== -1
        if (folderMatch) {
          return folder
        }
        const boardsMatch = folder.boards.filter(board =>
          board.name.toLowerCase().search(this.search.toLowerCase()) !== -1
        )

        const briefsMatch = folder.briefs.filter(brief =>
          brief.name.toLowerCase().search(this.search.toLowerCase()) !== -1
        )

        const spydersMatch = folder.spyders.filter(spyder =>
          spyder.name?.toLowerCase().search(this.search.toLowerCase()) !== -1
        )

        if (folderMatch || boardsMatch.length || briefsMatch.length || spydersMatch.length) {
          return {
            ...folder,
            boards: boardsMatch,
            briefs: briefsMatch,
            spyders: spydersMatch
          }
        }

        return null
      }).filter(Boolean)
    },
    getOpenFolders () {
      if (!this.search) {
        return this.openFolders
      } else {
        return this.getSearchedFolders.map(folder => folder.id)
      }
    }
  },
  watch: {
    getFolders () {
      this.getFolderItems()
    }
  },
  async mounted () {
    this.popupItem = this.$el
    this.SET_SPYDER_SUBSCRIPTIONS_LOADING(true)
    await this.fetchBoards()
    if (this.getUser.starredBoards) {
      this.SET_STARRED_BOARDS(
        this.getBoards.filter((board) =>
          this.getUser.starredBoards?.includes(board.id)
        )
      )
    }
    await this.fetchFolders()
    await this.fetchBriefs()
    await this.fetchSpyderSubscriptions()
    await this.getFolderItems()
    this.SET_SPYDER_SUBSCRIPTIONS_LOADING(false)
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
    ...mapActions('SpyderModule', ['fetchSpyderSubscriptions']),
    ...mapActions('BriefsModule', ['fetchBriefs']),
    ...mapMutations('AuthModule', ['SET_USER', 'SET_STARRED_BOARDS']),
    ...mapMutations('SpyderModule', ['SET_SPYDER_SUBSCRIPTIONS', 'SET_SPYDER_SUBSCRIPTIONS_LOADING']),
    ...mapMutations('BoardsModule', ['SET_FOLDERS']),
    openManageFolderModal (folder) {
      this.folderToManage = folder
      this.showFolderManageModal = true
    },
    openManageBoardModal (board) {
      this.showManageBoardModal = true
      this.boardToManage = board
    },
    openCreateBoardModal (folder) {
      this.boardToManage = {}
      this.selectedFolder = folder // Store the selected folder
      this.showManageBoardModal = true
      console.log("Selected Folder ", this.selectedFolder)
    },
    openMoveBoardModal (board, folder) {
      this.boardToMove = board
      this.folderToManage = folder

      this.showMoveBoardModal = true
    },
    openMoveSpyderModal (spyder, folder) {
      this.spyderToMove = spyder
      this.folderToManage = folder

      this.showMoveSpyderModal = true
    },
    openMoveBriefModal (brief, folder) {
      this.briefToMove = brief
      this.folderToManage = folder

      this.showMoveBriefModal = true
    },
    openFolderModal () {
      this.showFolderModal = true
    },
    openConfirmDeleteFolderModal (folder) {
      this.showConfirmDeleteFolderModal = true
      this.folder = folder
      this.folderName = folder.name
    },
    openConfirmDeleteBoardModal (board) {
      this.showConfirmDeleteBoardModal = true
      this.boardToDelete = board
      this.boardToDeleteName = board.name
    },
    openConfirmDeleteBriefModal (brief) {
      this.showConfirmDeleteBriefModal = true
      this.briefToDelete = brief
      this.briefToDeleteName = brief.name
    },
    toggleDefaultFolder () {
      this.defaultFolderOpen = !this.defaultFolderOpen
    },
    getFolderByPageId (pageId) {
      return this.getFolders.find(folder => folder?.spyderBrands?.includes(pageId))?.name || 'Default Folder'
    },
    async toggleStarredBoard (board) {
      // Track Event if board is starred
      window.analytics.track('Board Starred', {
        boardStarred: true
      })
      this.currentUser = this.getUser

      if (!this.currentUser.starredBoards) {
        this.currentUser.starredBoards = []
      }

      const index = this.currentUser.starredBoards.indexOf(board.id)
      if (index === -1) {
        this.currentUser.starredBoards.push(board.id)
      } else {
        this.currentUser.starredBoards.splice(index, 1)
      }

      this.SET_USER(this.currentUser)
      this.SET_STARRED_BOARDS(
        this.getBoards.filter((board) =>
          this.getUser.starredBoards?.includes(board.id)
        )
      )
      try {
        const db = firebase.firestore()
        await db.collection('auth-users').doc(this.currentUser.user_id).update({
          starredBoards: this.currentUser.starredBoards
        })
      } catch (err) {
        console.log(err)
      }
    },
    async removeSpyderBrand (brand) {
      const subscriptionId = this.getTeam?.id || this.getUser.user_id
      const adLibraryId = brand.subscription.pageId
      const removeSubscription = brand.subscription

      if (this.getFolderByPageId(adLibraryId) === 'Default Folder') {
        // the case for Default Folder
        const removePayload = removeSubscription.subscribers.filter(sub => sub !== subscriptionId)
        await FirebaseAPI.Spyder.update(removeSubscription.id, { subscribers: removePayload })
        this.SET_SPYDER_SUBSCRIPTIONS(this.getSpyderSubscriptions.filter(subscriptions => subscriptions.subscription.pageId !== adLibraryId))
        await this.fetchSpyderSubscriptions()
        this.fetchFolders()
        return
      }
      // updating subscriptions
      const removePayload = removeSubscription.subscribers.filter(sub => sub !== subscriptionId)
      await FirebaseAPI.Spyder.update(removeSubscription.id, { subscribers: removePayload })
      this.SET_SPYDER_SUBSCRIPTIONS(this.getSpyderSubscriptions.filter(subscriptions => subscriptions.subscription.pageId !== adLibraryId))

      // updating folders
      const updatedFolder = this.getFolders.find(folder => folder?.spyderBrands?.includes(brand.subscription.pageId))
      const updatePayload = updatedFolder.spyderBrands.filter(brandId => brandId !== brand.subscription.pageId)

      await FirebaseAPI.Folders.update(updatedFolder.id, { spyderBrands: updatePayload })
      this.SET_FOLDERS(this.getFolders.map(folder => folder.id === updatedFolder.id ? { ...folder, spyderBrands: updatePayload } : folder))
      await this.fetchSpyderSubscriptions()
      this.fetchFolders()
    },
    toggleFolderOpen (folderId) {
      if (this.openFolders.includes(folderId)) {
        this.openFolders = this.openFolders.filter((fId) => fId !== folderId)
      } else {
        this.openFolders.push(folderId)
      }

      localStorage.openFolders = JSON.stringify([...this.openFolders])
    },
    async copyBoardLink (boardId) {
      try {
        await this.$copyText(
          process.env.VUE_APP_BASE_URL +
        '/share/boards/' +
        encodeURIComponent(boardId) +
        '?user=' +
        encodeURIComponent(this.getUserName)
        )

        this.$showAlert({
          message: 'Copied share link to clipboard!',
          type: 'success'
        })
      } catch (err) {
        console.log(err)
      }
    },
    async copyBriefLink (briefId) {
      try {
        await this.$copyText(
          process.env.VUE_APP_BASE_URL +
        '/share/briefs/' +
        encodeURIComponent(briefId) +
        '?user=' +
        encodeURIComponent(this.getUserName)
        )

        this.$showAlert({
          message: 'Copied share link to clipboard!',
          type: 'success'
        })
      } catch (err) {
        console.log(err)
      }
    },
    async handleDeleteBoard () {
      this.loadingDeleteBoard = true
      this.showConfirmDeleteBoardModal = false

      try {
        await FirebaseAPI.Boards.remove(this.boardToDelete.id)

        this.$showAlert({
          message: 'Board deleted successfully',
          type: 'success'
        })

        // Make sure the side bar is up to date
        await this.fetchBoards()
        await this.fetchFolders()

      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        if (this.$route.params.id === this.boardToDelete.id) {
          this.$router.push({ name: 'LibraryView' })
        }
        this.loadingDeleteBoard = false
      }
    },
    async handleDeleteBrief () {
      this.loadingDeleteBrief = true
      this.showConfirmDeleteBriefModal = false

      try {
        await FirebaseAPI.Briefs.remove(this.briefToDelete.id)

        this.$showAlert({
          message: 'Brief deleted successfully',
          type: 'success'
        })

        // Make sure the side bar is up to date
        await this.fetchBriefs()
        await this.fetchFolders()

      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        if (this.$route.params.id === this.briefToDelete.id) {
          this.$router.push({ name: 'LibraryView' })
        }
        this.loadingDeleteBrief = false
      }
    },
    async archiveBrief (briefId) {
      FirebaseAPI.Briefs.update(briefId, { archived: true })
      await this.fetchBriefs()
      this.getFolderItems()
      this.$showAlert({
        message: 'Archived Selected Briefs',
        type: 'success'
      })
    },
    closeDropdown () {
      this.$emit('update:leaveDropdown', true)
      this.showCreateOptions = false
      setTimeout(() => {
        this.$emit('update:leaveDropdown', false)
      }, 10)
    },
    async deleteFolder () {
      this.showConfirmDeleteFolderModal = false
      // Make sure the folder being deleted isn't an experts folder
      if (this.getUser.expertProfile?.selectedFolder === this.folder.id) {
        this.$showAlert({
          message:
            'This is an experts folder, change your experts folder before deleting this',
          type: 'error'
        })

        return
      }

      this.loadingDelete = true

      await FirebaseAPI.Folders.remove(this.folder.id)

      // Refetch the boards and folders for the sidebar
      this.fetchFolders()

      // finished actions
      this.loadingDelete = false
      this.$emit('close')
    },
    async getFolderItems () {
      let folders = this.getFolders.map((folder) => ({
        ...folder,
        boards: [],
        briefs: [],
        spyders: []
      }))

      // Spread so we don't modify global state directly
      const boards = [...this.getBoards]
      const briefs = [...this.getBriefs]
      const spyders = [...this.getSpyderSubscriptions]

      const defaultFolder = {
        name: 'Default Folder',
        id: 'default',
        boards: [],
        briefs: [],
        spyders: []
      }

      // Loop through the boards to find their master folder
      while (boards.length) {
        const board = boards.pop()

        // Find the boards folder
        const folderIndex = folders.findIndex((folder) =>
          folder.boardIds.includes(board.id)
        )

        // Add board to folder
        if (folderIndex !== -1) {
          folders[folderIndex].boards.push(board)
        } else {
          // No folder - add it to the default folder
          defaultFolder.boards.push(board)
        }
      }

      // Loop through the briefs to find their master folder
      while (briefs.length) {
        const brief = briefs.pop()

        // Find the boards folder
        const folderIndex = folders.findIndex((folder) => {
          if (folder.briefIds) {
            return folder.briefIds.includes(brief.id)
          } else {
            return false
          }
        })

        // Add board to folder
        if (folderIndex !== -1) {
          folders[folderIndex].briefs.push(brief)
        } else {
          // No folder - add it to the default folder
          defaultFolder.briefs.push(brief)
        }
      }

      // Loop through the spyders to find their master folder
      while (spyders.length) {
        const spyder = spyders.pop()

        // Find the spyders folder
        const folderIndex = folders.findIndex((folder) =>
          folder.spyderBrands?.includes(spyder.subscription.pageId)
        )

        // Add spyders to folder
        if (folderIndex !== -1) {
          folders[folderIndex].spyders.push(spyder)
        } else {
          // No folder - add it to the default folder
          defaultFolder.spyders.push(spyder)
        }
      }

      // Add default folder to the top of the folder list
      folders.unshift(defaultFolder)

      // Sort the briefs and boards alphabetically
      folders = folders.map((folder) => {
        const copyFolder = { ...folder }

        copyFolder.briefs = copyFolder.briefs.sort((a, b) =>
          a.name < b.name ? -1 : 1
        )
        copyFolder.boards = copyFolder.boards.sort((a, b) =>
          a.name < b.name ? -1 : 1
        )

        return copyFolder
      })

      // Set folders
      this.folders = folders

      // Check local storage for open folders structure
      if (localStorage.openFolders) {
        this.openFolders = JSON.parse(localStorage.openFolders)
      }
    },
    routeToAddSpyder (folderId) {
      // Using a counter param ensures a route change even if the route is the same
      this.spyderLinkCounter++
      this.$router.push({
        name: 'SpyderBrandsView',
        query: { folderId, c: this.spyderLinkCounter }
      })
    },
    routeToCreateBrief (folderId) {
      this.briefsLinkCounter++
      this.$router.push({
        name: 'BriefsView',
        query: { folderId, c: this.briefsLinkCounter }
      })
    }
  }
}
</script>

<style scoped>
.folder-style {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.06);
  background-color: #ffffff14;
}

.dropdown {
  background: rgba(255, 255, 255, 0.8);

  box-shadow: 0px 24px 32px -12px rgba(54, 57, 74, 0.24),
    0px 1px 2px rgba(164, 172, 185, 0.16),
    0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  backdrop-filter: blur(10px);
}
</style>
