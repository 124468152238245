<!-- This component wrapper is designed to handle the rendering of elements in an infinitely scrollable list. -->
<!-- Elements that are not visible within the viewport (+ a buffer) will be unmounted from the DOM. -->
<!-- This prevents the DOM from getting overloaded with hundreds/thousands of expensive components. -->

<template>
  <div :ref="`loader-wrapper-${identifier}`" :style="{width: wrapperDimensions.width, height: wrapperDimensions.height}"
  :class="{'opacity-0': enableInitialMountFadeIn}">
    <transition>
      <div v-if="isLoaded">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import { observe, unobserve } from '../../utils/scrollUnloadObserver'

export default {
  name: 'BaseDynamicLoader',
  props: {
    // A unique key to identify the element
    identifier: {
      type: String,
      required: true
    },
    // CSS selector for the element that will watch for the target element (scrolling container)
    observerElmSelector: {
      type: String,
      default: '.main-layout'
    },
    // The buffer determines how far from the viewport the element should be before loading/unloading
    buffer: {
      type: Number,
      default: 200
    },
    // Whether or not to fade in the content on initial mount
    enableInitialMountFadeIn: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isLoaded: true,
      wrapperDimensions: { width: 'auto', height: 'auto' },
      unloadTimeout: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      requestAnimationFrame(() => {
        const loaderWrapper = this.$refs[`loader-wrapper-${this.identifier}`]
        if (this.enableInitialMountFadeIn) {
          setTimeout(() => { loaderWrapper.classList.add('onmount-fade-in') }, 10)
        }
        const root = document.querySelector(this.observerElmSelector) || null
        const observerOptions = {
          root: root,
          rootMargin: `${this.buffer}px 0px ${this.buffer}px 0px`,
          threshold: 0
        }
        observe(loaderWrapper, this.handleIntersection, this, observerOptions)
      })
    })
  },
  beforeDestroy () {
    const loaderWrapper = this.$refs[`loader-wrapper-${this.identifier}`]
    unobserve(loaderWrapper)
    if (this.unloadTimeout) clearTimeout(this.unloadTimeout)
  },
  methods: {
    handleIntersection (entry) {
      if (entry.isIntersecting) {
        if (this.unloadTimeout) clearTimeout(this.unloadTimeout)
        this.wrapperDimensions = { width: 'auto', height: 'auto' }
        this.isLoaded = true
      } else {
        // Debounce the unload logic
        if (this.unloadTimeout) clearTimeout(this.unloadTimeout)
        this.unloadTimeout = setTimeout(() => {
          // Ensure the content is still out of view
          const contentRect = entry.target.getBoundingClientRect()
          const viewportHeight = window.innerHeight
          if (contentRect.top >= viewportHeight + this.buffer || contentRect.bottom <= -this.buffer) {
            // Maintain the wrapper dimensions to prevent layout shifts
            this.wrapperDimensions = { width: `${contentRect.width}px`, height: `${contentRect.height}px` }
            this.isLoaded = false
          }
        }, 500)
      }
    }
  }
}
</script>

<style scoped>
/* Have content fade-in to make the load less jarring */
.v-enter-active {
  transition: opacity 75ms ease-in-out;
}
.v-enter, .v-enter-from {
  opacity: 0;
}
.v-enter-to {
  opacity: 1;
}
.onmount-fade-in {
  animation: fadeIn 75ms ease-in-out 1 forwards;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>