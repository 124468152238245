import firebase from '../../config/FirebaseConfig'

async function authSocket (socket) {
  // Update auth token on existing socket
  socket.auth = { token: await firebase.auth().currentUser.getIdToken() }

  // Reconnect to apply the new auth token
  socket.disconnect().connect()
}

export default authSocket
