// Imports
import firebase from '../config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'

// Setup
const db = firebase.firestore()

// Get All
const getAll = async () => {
  const boards = await db.collection('boards').get()

  return boards.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get All By User
const getAllByUser = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('boards').where('created_by', '==', user.uid).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get All By User
const getAllByTeam = async (teamId) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('boards').where('teamId', '==', teamId).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get By ID
const getByID = async (boardID) => {
  const boards = await db.collection('boards').doc(boardID).get()

  return {
    id: boards.id,
    ...boards.data()
  }
}

const create = async (payload, folder = null) => {
  const user = firebase.auth().currentUser

  payload.created_by = user.uid // Legacy snake casing
  payload.createdAt = +new Date()

  const { id } = await db.collection('boards').add(payload)

  window.Intercom('trackEvent', 'Created Board', {
    description: payload.description,
    name: payload.name
  })

  // Add board to folder, if applicable
  if (folder) {
    const toAddPayload = { boardIds: folder.boardIds ? [...folder.boardIds, id] : [id] }
    await FirebaseAPI.Folders.update(folder.id, toAddPayload)
  }

  return id
}

// Update Board
const update = async (boardId, payload) => {
  return await db
    .collection('boards')
    .doc(boardId)
    .update(payload)
}

// Remove Board
const remove = async (boardId) => {
  return db.collection('boards').doc(boardId).delete()
}

export default {
  getAll,
  getAllByUser,
  getAllByTeam,
  getByID,
  create,
  remove,
  update
}
