<template>
  <!-- Manage Board Modal -->
  <BaseModal @close="$emit('close')" cardWidth="490px" noPadding noToolbarPadding noExit>
    <template #toolbar>
      <div class="flex items-center gap-3 pt-1 pr-5 pb-1">
        <img src="../../assets/images/manage-board-logo.png" alt="" style="width: 46px; height: 58px" />
        <div class="flex flex-col" style="padding-bottom: 14px">
          <BaseText type="label" size="md" class="text-text-loud">
            {{ board.id ? "Edit Board" : "Create Board" }}
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-normal">
            Boards hold your saved ads
          </BaseText>
        </div>
      </div>
      <button class="absolute top-3 right-3 p-1.5" @click="$emit('close')">
        <TagRemoveIcon stroke="#5E6678" />
      </button>
    </template>
    <template #default>
      <div class="w-full flex flex-col p-3 border-t border-border-normal">
        <div class="w-full flex items-center justify-between">
          <BaseText type="label" size="sm" class="w-full text-text-muted">
            Board Name
          </BaseText>
          <BaseText v-if="getBoardNameErrors" type="body" size="xs" class="text-primary-red-100 min-w-max">
            {{ getBoardNameErrors }}
          </BaseText>
        </div>
        <input v-model="currentBoard.name" class="form-input w-full px-2 py-1.5 mt-1 rounded-md"
        placeholder="client_nike" @input="boardNameInputChanged" @keypress.enter.prevent="handleSaveBoard"/>
        <div class="w-full flex items-center gap-1 mt-3">
          <BaseText type="label" size="sm" class="text-text-muted">
            Description
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-subdued">
            (Optional)
          </BaseText>
        </div>
        <input v-model="currentBoard.description" class="form-input w-full px-2 py-1.5 mt-1 rounded-md"
        placeholder="A brief description of your board" @keypress.enter.prevent="handleSaveBoard" />
        <div v-if="!board.id" class="w-full flex items-center gap-1 mt-3">
          <BaseText type="label" size="sm" class="text-text-muted">
            Location
          </BaseText>
        </div>
        <!-- Folder Select Dropdown -->
        <div v-if="!board.id" class="relative w-full h-9 mb-2">
          <div class="folder-dropdown-container w-full flex flex-col rounded-lg mt-1 overflow-hidden" v-on-clickaway="() => { foldersExpanded = false }"
          :style="{ height: foldersExpanded ? `${Math.min(36 + (folderOptions.length * 36) + 4, 203)}px` : '36px'}">
            <button class="w-full flex items-center pl-3 pr-1.5 py-2 flex-nowrap" 
            @click.prevent="foldersExpanded = !foldersExpanded">
              <FolderIcon class="text-icon-normal mr-2 flex-shrink-0" />
              <BaseText type="body" size="sm" class="flex-grow text-text-muted truncate text-left mr-2 min-w-0">
                {{ selectedFolder.name || '' }}
              </BaseText>
              <div class="transform transition-transform flex-shrink-0" :style="{transform: foldersExpanded ? 'scaleY(-1)' : ''}">
                <ChevronIcon class="text-icon-normal" />
              </div>
            </button>
            <transition>
              <div v-if="!isScrollTop && foldersExpanded" class="top-fade-overlay absolute left-0 right-0 h-6 z-10 pointer-events-none" style="top: 37px" />
            </transition>
            <div class="flex-grow flex flex-col gap-1 p-1 bg-white cursor-pointer border-t border-border-normal
            overflow-y-scroll scrollbar-hide min-h-0" ref="folder-list">
              <button v-for="(folder, index) in folderOptions" :key="`change-folder-${index}`"
              class="group flex items-center rounded-lg px-2 py-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap"
              @click.prevent="selectedFolder = folder, foldersExpanded = false">
                <FolderIcon class="transition-colors text-neutral-400 group-hover:text-icon-normal mr-2 flex-shrink-0" />
                <BaseText type="body" size="sm" class="text-text-muted flex-grow truncate text-left min-w-0">
                  {{ folder.name }}
                </BaseText>
              </button>
            </div>
            <transition name="fade">
              <div v-if="!isMaxScroll && foldersExpanded" class="bottom-fade-overlay absolute bottom-0 left-0 right-0 h-6 z-10 pointer-events-none" />
            </transition>
          </div>
        </div>
        <!-- Bulk download section -->
        <div v-if="board.id" class="w-full flex flex-col p-3 mt-3 rounded-md bg-neutral-25">
          <BaseText type="label" size="sm" class="w-full text-text-muted">
            Bulk Download
          </BaseText>
          <BaseText type="body" size="sm" class="w-full text-text-subdued mt-1">
            Download this board as a .ZIP folder (videos and images only)
          </BaseText>
          <div class="w-full flex items-center mt-3 gap-1.5">
            <!-- Download board button -->
            <button class="download-board-btn group flex items-center gap-1.5 px-2.5 py-1.5 rounded-md"
            :class="{'downloading cursor-default': loadingDownload}" :disabled="loadingDownload" type="button"
            @click.prevent="initializeBoardExport">
              <BaseLoadingSpinnerCircle v-if="loadingDownload" class="text-text-subdued" :duration="1" />
              <DownloadIcon v-else class="text-icon-normal transition-colors group-hover:text-icon-muted" />
              <BaseText type="label" size="sm" class="transition-colors" :class="loadingDownload ? 'text-text-subdued' : 'text-text-muted'">
                {{ loadingDownload ? "Downloading" : "Download" }} Board
              </BaseText>
            </button>
            <!-- Download progress indicator -->
            <div v-if="loadingDownload" class="flex-grow flex flex-col gap-1 px-3">
              <BaseText type="body" size="xs" class="text-text-disabled">
                {{ downloadProgressText }}
              </BaseText>
              <div class="w-full h-1.5 p-px rounded-full bg-neutral-50">
                <div ref="progress-bar" class="h-full w-0 rounded-full bg-neutral-300" style="transition: width 100ms ease-in-out" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Update/Create button -->
      <div class="flex items-center justify-between p-5 border-t border-border-normal">
        <button class="px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
        @click.prevent="$emit('close')">
          <BaseText type="label" size="sm" class="text-text-muted">
            Cancel
          </BaseText>
        </button>
        <BaseButton primary :loading="loadingSave" :disabled="loadingSave"
        @click="handleSaveBoard">
          {{ board.id ? "Update Board" : "Create Board" }}
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'

// Icons
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'
import FolderIcon from '../globals/Icons/SpyderV2Icons/FolderIcon.vue'
import ChevronIcon from '../globals/Icons/ChevronIcon.vue'
import DownloadIcon from '../globals/Icons/DownloadIcon.vue'

let isFramePending = false // Prevents render update buffering for the download progress bar

export default {
  name: 'ManageBoardModal',
  mixins: [clickaway],
  components: {
    TagRemoveIcon,
    FolderIcon,
    ChevronIcon,
    DownloadIcon
  },
  props: {
    // Board
    board: {
      type: Object,
      default: () => ({})
    },
    initialFolder: {
      type: Object,
      default: () => ({ id: null, name: 'Default Folder' })
    }
  },
  data () {
    return {
      currentBoard: {},
      selectedFolder: this.initialFolder,
      loadingSave: false,

      // Folder dropdown states
      foldersExpanded: false,
      isScrollTop: true,
      isMaxScroll: false,

      // Board download states
      loadingDownload: false,
      downloadProgressText: '',
      downloadPollInterval: null,
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['isFreeTier', 'getTeam']),
    ...mapGetters('BoardsModule', ['getBoards', 'getFolders']),
    folderOptions () {
      const folders = this.getFolders.filter(folder => folder.id !== this.selectedFolder.id)
      if (this.selectedFolder.id) {
        folders.push({ id: null, name: 'Default Folder' })
      }
      return folders.sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically
    },
    // Get Board Name Errors
    getBoardNameErrors () {
      if (!this.$v.currentBoard.$anyDirty) return null
      if (!this.$v.currentBoard.name.required) return 'Name is required'
      return null
    }
  },
  validations: {
    currentBoard: {
      name: {
        required
      }
    }
  },
  mounted () {
    this.currentBoard = { ...this.board }
    if (this.currentBoard.name) this.currentBoard.name = this.currentBoard.name.substring(1)
    if (!this.board.id) {
      this.$nextTick(() => {
        this.handleDropdownScroll()
        this.$refs['folder-list'].addEventListener('scroll', this.handleDropdownScroll)
      })
    }
  },
  beforeDestroy () {
    if (!this.board.id) this.$refs['folder-list'].removeEventListener('scroll', this.handleDropdownScroll)
    if (this.downloadPollInterval) clearInterval(this.downloadPollInterval)
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),

    // ================================================================================
    // ================================= DATA METHODS =================================
    // ================================================================================

    // Handle Save Board
    async handleSaveBoard () {
      this.$v.$reset()
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.loadingSave = true
      try {
        const payload = {
          ads: this.currentBoard.ads || [],
          description: this.currentBoard.description || '',
          name: `#${this.currentBoard.name
            .replace(/ /g, '_')
            .toLowerCase()
            .trim()}`,
        }
        if (this.getTeam) payload.teamId = this.getTeam.id

        const boardFolder = (this.selectedFolder && this.selectedFolder.id)
          ? this.selectedFolder
          : null

        if (!this.currentBoard.id) {
          await FirebaseAPI.Boards.create(payload, boardFolder)
          this.$showAlert({
            message: 'Board created successfully',
            type: 'success'
          })
        } else {
          await FirebaseAPI.Boards.update(this.currentBoard.id, payload)
          this.$showAlert({
            message: 'Board updated successfully',
            type: 'success'
          })
          this.$emit('save', this.currentBoard.id)
        }

        await this.fetchBoards()
        await this.fetchFolders()

        this.$emit('close')
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: `Error ${this.currentBoard.id ? 'updating' : 'creating'} board`,
          type: 'error'
        })
      } finally {
        this.loadingSave = false
      }
    },

    // ============================ BOARD DOWNLOAD METHODS ============================

    async initializeBoardExport () {
      if (!this.board.id || this.loadingDownload) return

      this.downloadProgressText = 'Requesting board export... (1/3)'
      this.loadingDownload = true

      // Initialize the board export job
      try {
        const {data, status} = await ForeplayAPI.Ads.createBoardExportJob(this.board.id)
        if (status !== 200 && status !== 202) throw new Error(data.message)
      } catch (error) {
        console.error('Error initializing board export:', error)
        this.$showAlert({
          message: error,
          type: 'error'
        })
        this.loadingDownload = false
        this.downloadProgressText = ''
        return
      }

      // Define the job status request function
      const getJobStatus = async () => {
        const {data, status} = await ForeplayAPI.Ads.getBoardExportStatus(this.board.id)
        if (status !== 200) throw new Error(data.message)

        // If the job is finished, return the download URL
        if (data.status === 1 && data.url) {
          return {
            url: data.url,
            progress: 100
          }
        }
        // Otherwise, return the job progress
        return { progress: data.progress }
      }

      // Poll the job status every 1.5 seconds
      const timeout = Date.now() + 300000 // 5 minutes
      try {
        let serverIsProcessing = false
        const executePoll = async () => { 
          const pollResult = await getJobStatus()
          if (pollResult.url) {
            // Once the export job gives a download URL, clear the polling interval and download the zip
            clearInterval(this.downloadPollInterval)
            this.executeBoardDownload(pollResult.url)
            return false // Prevent polling from initiating
          }
          else if (pollResult.progress > 0) {
            // Progress will start incrementing when the job moves from pending to processing
            if (!serverIsProcessing) {
              this.downloadProgressText = 'Server processing board... (2/3)'
              serverIsProcessing = true
            }
            const progress = Math.round(pollResult.progress / 2)
            this.updateDownloadProgressBar(progress)
            return true
          }
          else {
            // If the job is still pending, check if the timeout has been reached
            if (Date.now() > timeout) throw new Error('Export request timed out')
            return true
          }
        }

        // Check the job status immediately and start polling if necessary
        if (await executePoll()) {
          this.downloadPollInterval = setInterval(executePoll, 1500)
        }
      } catch (error) {
        clearInterval(this.downloadPollInterval)
        console.error('Error downloading board:', error)
        this.$showAlert({
          message: error,
          type: 'error'
        })
        this.loadingDownload = false
        this.downloadProgressText = ''
      }
    },
    async executeBoardDownload(url) {
      this.downloadProgressText = 'Downloading board contents... (3/3)'
      // Fetch the asset
      const response = await fetch(url, { mode: 'cors' })
      if (!response.ok) {
        throw new Error('Failed to fetch board export')
      }

      // Get the content length to track download progress
      const contentLength = response.headers.get('Content-Length')
      if (!response.body || !contentLength) {
        throw new Error('Readable steams or content length not supported')
      }

      // Create a readable stream
      const totalBytes = parseInt(contentLength, 10)
      let loadedBytes = 0
      const reader = response.body.getReader()
      const stream = new ReadableStream({
        start: async (controller) => {
          while (true) {
            const { done, value } = await reader.read()
            if (done) break

            loadedBytes += value.byteLength
            this.updateDownloadProgressBar(50 + Math.round((loadedBytes / totalBytes) * 50))

            controller.enqueue(value)
          }
          controller.close()
        }
      })

      // Create a new blob from the stream
      const blob = await new Response(stream).blob()
      this.loadingDownload = false
      this.downloadProgressText = ''

      // Create a temporary download link and simulate click to trigger direct download
      const downloadUrl = URL.createObjectURL(blob)
      const filename = `${this.board.name.substring(1).replace(/ /g, '_').toLowerCase()}-export.zip`
      const anchor = document.createElement('a')
      anchor.href = downloadUrl
      anchor.download = filename
      anchor.addEventListener('click', (event) => { event.stopPropagation() })
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)

      // Revoke the URL to free up memory
      URL.revokeObjectURL(url)
    },

    // ================================================================================
    // ================================== UI METHODS ==================================
    // ================================================================================

    updateDownloadProgressBar (progress) {
      if (isFramePending) return
      isFramePending = true
      requestAnimationFrame(() => {
        this.$refs['progress-bar'].style.width = `${progress}%`
        isFramePending = false
      })
    },
    handleDropdownScroll () {
      console.log('scrolling')
      const scrollableElm = this.$refs['folder-list']
      this.isScrollTop = scrollableElm.scrollTop === 0
      this.isMaxScroll = Math.ceil(scrollableElm.scrollHeight - (scrollableElm.scrollTop)) <= (scrollableElm.clientHeight + 2) // 2px buffer
    },
    boardNameInputChanged (event) {
      const name = `${event.target.value.replace(/ /g, '_').toLowerCase()}`
      this.currentBoard.name = name
    },
  }
}
</script>

<style scoped>
.form-input {
  border: none;
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;

  /* Body/Small */
  font-feature-settings: 'ss01' on, 'cv10' on, 'liga' off, 'calt' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.form-input:focus {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.10), 0px 0px 0px 1px rgba(0, 56, 108, 0.10);
}
.form-input::placeholder {
  color: #808899;
  opacity: 0.8;
  transition: opacity 150ms ease-in-out;
}
.form-input:focus::placeholder {
  opacity: 0.5;
}
.folder-dropdown-container {
  position: absolute;
  transition: height 100ms ease-in-out;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.top-fade-overlay {
  background: linear-gradient(180deg, #FFFFFF, transparent);
}
.bottom-fade-overlay {
  background: linear-gradient(0deg, #FFFFFF, transparent);
}
.download-board-btn {
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
}
.download-board-btn:hover, .download-board-btn.downloading {
  background-color: #ECEFF3; /* bg-neutral-50 */
  box-shadow: none;
}

/* ========= Vue <transition> classes ========= */
.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.v-enter, .v-enter-from, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>
